<template>
  <div>
    <Casual v-if="surveyType == 'casual'" />
    <Period v-else />
  </div>
</template>

<script>
import Casual from "@/components/home/Casual";
import Period from "@/components/home/Period";

export default {
  components: {
    Casual,
    Period,
  },
  computed: {
    surveyType() {
      return this.$store.state.surveyType;
    },
  },
};
</script>


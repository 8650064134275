var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('el-header',[_c('div',[_c('div',{staticClass:"nav-menu"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/logo/logo-white.png")},on:{"click":_vm.gotoWebMain}}),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"language"},[_c('span',{staticClass:"select",on:{"click":function($event){_vm.$i18n.locale = 'th'}}},[_vm._v("ไทย ")]),_c('span',[_vm._v("|")]),_c('span',{staticClass:"select",on:{"click":function($event){_vm.$i18n.locale = 'en'}}},[_vm._v(" EN")])]),_c('font-awesome-icon',{staticClass:"nav-icon",attrs:{"icon":"fa-solid fa-bars"},on:{"click":function($event){_vm.dialogVisible = true}}}),_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link cursor-pointer"},[_c('div',{staticClass:"user-box"},[_c('avatar',{attrs:{"rounded":true,"username":_vm.user.email,"src":("" + (_vm.user.profile_photo_url)),"size":30}}),_c('span',[_vm._v(" "+_vm._s(_vm.user.name))]),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})],1)]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"profile"}},[_vm._v(_vm._s(_vm.$t("message.menuProfile")))]),(
                  _vm.surveyType == 'casual' &&
                  (_vm.user.type === 'super-admin' || _vm.user.type === 'admin')
                )?_c('el-dropdown-item',{attrs:{"command":"responseReport"}},[_vm._v(_vm._s(_vm.$t("message.responseReport")))]):_vm._e(),(
                  _vm.surveyType == 'casual' &&
                  (_vm.user.type === 'super-admin' || _vm.user.type === 'admin')
                )?_c('el-dropdown-item',{attrs:{"command":"questionReport"}},[_vm._v(_vm._s(_vm.$t("message.questionReport")))]):_vm._e(),(
                  _vm.surveyType == 'casual' &&
                  (_vm.user.type === 'super-admin' || _vm.user.type === 'admin')
                )?_c('el-dropdown-item',{attrs:{"command":"statisticsReport"}},[_vm._v(_vm._s(_vm.$t("message.statisticsReport")))]):_vm._e(),(
                  _vm.surveyType == 'casual' &&
                  (_vm.user.type === 'super-admin' || _vm.user.type === 'admin')
                )?_c('el-dropdown-item',{attrs:{"command":"statisticsPeriodReport"}},[_vm._v(_vm._s(_vm.$t("message.statisticsPeriodReport")))]):_vm._e(),(_vm.surveyType == 'casual' && _vm.user.type === 'super-admin')?_c('el-dropdown-item',{attrs:{"command":"setting"}},[_vm._v(_vm._s(_vm.$t("message.setting")))]):_vm._e(),_c('el-dropdown-item',{attrs:{"command":"signOut"}},[_vm._v(_vm._s(_vm.$t("message.logout")))])],1)],1)],1)])])]),_c('el-main',{class:_vm.surveyType},[(_vm.breadcrumbs.length > 0)?_c('el-breadcrumb',{attrs:{"separator":"/"}},_vm._l((_vm.breadcrumbs),function(item,index){return _c('el-breadcrumb-item',{key:index,attrs:{"to":{ path: item.path }}},[_vm._v(_vm._s(item.i18n !== undefined ? _vm.$t(("breadcrums." + (item["name"]))) : item.name))])}),1):_vm._e(),_vm._t("default")],2),_c('el-dialog',{attrs:{"title":"","visible":_vm.dialogVisible,"fullscreen":true},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('div',{staticClass:"title-logo"},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/logo/logo-color.png")}})]),_c('div',{staticClass:"list-menu"},[_c('p',{on:{"click":function($event){return _vm.handleCommand('profile')}}},[_vm._v(" "+_vm._s(_vm.$t("message.menuProfile"))+" ")]),(
            _vm.surveyType == 'casual' &&
            (_vm.user.type === 'super-admin' || _vm.user.type === 'admin')
          )?_c('p',{on:{"click":function($event){return _vm.handleCommand('responseReport')}}},[_vm._v(" "+_vm._s(_vm.$t("message.responseReport"))+" ")]):_vm._e(),(
            _vm.surveyType == 'casual' &&
            (_vm.user.type === 'super-admin' || _vm.user.type === 'admin')
          )?_c('p',{on:{"click":function($event){return _vm.handleCommand('questionReport')}}},[_vm._v(" "+_vm._s(_vm.$t("message.questionReport"))+" ")]):_vm._e(),(
            _vm.surveyType == 'casual' &&
            (_vm.user.type === 'super-admin' || _vm.user.type === 'admin')
          )?_c('p',{on:{"click":function($event){return _vm.handleCommand('statisticsReport')}}},[_vm._v(" "+_vm._s(_vm.$t("message.statisticsReport"))+" ")]):_vm._e(),(
            _vm.surveyType == 'casual' &&
            (_vm.user.type === 'super-admin' || _vm.user.type === 'admin')
          )?_c('p',{on:{"click":function($event){return _vm.handleCommand('statisticsPeriodReport')}}},[_vm._v(" "+_vm._s(_vm.$t("message.statisticsPeriodReport"))+" ")]):_vm._e(),(_vm.surveyType == 'casual' && _vm.user.type === 'super-admin')?_c('p',{on:{"click":function($event){return _vm.handleCommand('setting')}}},[_vm._v(" "+_vm._s(_vm.$t("message.setting"))+" ")]):_vm._e(),_c('p',{on:{"click":function($event){return _vm.handleCommand('signOut')}}},[_vm._v(_vm._s(_vm.$t("message.logout")))]),_c('div',{staticClass:"language"},[_c('span',{staticClass:"select",on:{"click":function($event){_vm.$i18n.locale = 'th'}}},[_vm._v("ไทย ")]),_c('span',[_vm._v("|")]),_c('span',{staticClass:"select",on:{"click":function($event){_vm.$i18n.locale = 'en'}}},[_vm._v(" EN")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
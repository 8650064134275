<template>
  <DashboardTemplateCasual
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :title="$t('message.titleHome')"
  >
    <div class="title-box">
      <span class="title text-gray"
        >{{ $t("home.prefixTotal") }} {{ tests.length }}
        {{ $t("home.suffixTotal") }}</span
      >
      <div class="menu">
        <div v-if="surveyType == 'period'">
          <span v-if="timeType === 1" class="text-gray">{{
            `${$t("home.remainingTime")}: ${time}`
          }}</span>
          <span v-if="timeType === 2" class="text-gray">{{
            `${$t("home.timeSpent")}: ${time}`
          }}</span>
        </div>
        <el-button
          v-else
          @click="goToViewScore()"
          :disabled="!sessionNow"
          :title="!sessionNow ? $t('home.descriptionViewScore') : null"
          class="btn-view-score"
          ><img :src="require(`../../assets/image/image-view-score.png`)" />
          {{ $t("home.btnViewScore") }}</el-button
        >
      </div>
    </div>

    <el-row :gutter="20" class="gutter-20">
      <el-col v-for="item in tests" :key="item.id" :span="24" :sm="12" :md="8">
        <el-card
          :body-style="{ padding: '0px' }"
          class="test-card"
          :class="{
            success: item.topLevel !== null && !lockCasual(item),
            'lock-casual': lockCasual(item),
          }"
        >
          <div class="body">
            <div class="content">
              <div class="top">
                <img :src="require(`../../assets/image/${item.code}.png`)" />

                <span v-if="lockCasual(item)" class="icon-box lock">
                  <i class="el-icon-lock"></i>
                </span>

                <span v-else-if="item.topLevel === null" class="new-box"
                  >NEW</span
                >

                <span
                  v-else-if="item.topLevel !== null && !lockCasual(item)"
                  class="icon-box success"
                >
                  <i class="el-icon-check"></i>
                </span>
              </div>
              <div class="center">
                <h3>{{ item.name }}</h3>
                <el-button
                  @click="goToTestLevel(item._id)"
                  class="btn-view-test"
                  :class="{
                    lock: lockCasual(item),
                  }"
                >
                  <span>{{ $t("home.viewTest") }}</span>
                  <i class="el-icon-arrow-right"></i
                ></el-button>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </DashboardTemplateCasual>
</template>

<script>
import DashboardTemplateCasual from "@/template/DashboardTemplateCasual";
import { HTTP, HTTP_WEB_MAIN } from "@/service/axios";
export default {
  components: {
    DashboardTemplateCasual,
  },
  computed: {
    surveyType() {
      return this.$store.state.surveyType;
    },
    steps() {
      return this.$store.state.steps;
    },
  },

  data() {
    return {
      tests: [],
      loading: true,
      langs: ["th", "en"],
      startTime: null,
      time: null,
      timeType: null,
      stateNumber: null,
      sessionId: null,
      sessionNow: "",
    };
  },
  created() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  mounted() {
    this.fetchTestPeriod();
    this.fetchTests();
    this.fetchScoreOfWebMain();
  },
  methods: {
    async fetchScoreOfWebMain() {
      try {
        HTTP_WEB_MAIN.defaults.headers.common.Authorization = `Bearer wKdzBB9ObcLK0dhg`;
        let res = await HTTP_WEB_MAIN.get(
          `/api/users/${this.user.id}/test/${52}/latest`
        );
        console.log("fetchScoreOfWebMain", res);
        if (res.status === 200) {
          this.sessionNow =
            res.data.results.length > 0 ? res.data.results[0].session_id : "";
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    goToViewScore() {
      window.open(
        `https://mindanalytica.com/surveys/${this.sessionNow}/result`
      );
    },
    goToTestLevel(id) {
      this.$router.push(`/test/${id}`);
    },
    async fetchTests() {
      try {
        let path = `/tests?orderByField=number&orderBy=asc`;
        if (this.surveyType == "period") {
          path += "&periodAllow=yes";
        }
        let res = await HTTP.get(path);

        if (res.data.success) {
          let array = [];
          res.data.result.rows.map((item) => {
            let obj = item;

            array.push(obj);
          });
          this.tests = array;
        } else {
          this.tests = [];
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
        this.tests = [];
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    async fetchTestPeriod() {
      try {
        let res = await HTTP.get(`/log/test/periods/by/${this.surveyType}`);

        if (res.data.success) {
          if (res.data.result) {
            this.startTime = res.data.result.startTime;

            this.stateNumber = res.data.result.stateNumber;

            this.sessionId = res.data.result.sessionId;

            this.setTime();
            this.$store.commit("SET_STEPS", res.data.result.steps);
            if (typeof res.data.result.disconnect !== "undefined") {
              this.$store.commit("SET_DISCONNECT", res.data.result.disconnect);
            }
          } else {
            this.$socket.emit("endGame", {
              userId: this.user.id,
            });
          }
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    setTime() {
      var myInterval = setInterval(() => {
        let date = null;
        let type = null;

        if (Date.now() > new Date(this.startTime).getTime() + 9000000) {
          date = new Date(this.startTime).getTime() + 10800000;
          type = 1;
        } else {
          date = new Date(this.startTime).getTime();
          type = 2;
        }

        this.timeType = type;
        this.time = this.getTime(date, type);

        if (Date.now() > new Date(this.startTime).getTime() + 10800000) {
          clearInterval(myInterval);
        }
      }, 1000);
    },
    getTime(date, type) {
      let now = Date.now();
      let time = 0;

      if (type === 1) {
        time = (date - now) / 1000;
      } else {
        time = (now - date) / 1000;
      }

      let h = 0;
      let m = 0;
      let s = 0;

      h = parseInt(time / 3600);

      time = time - h * 3600;

      if (time > 0) {
        m = parseInt(time / 60);
        time = time - m * 60;

        if (time > 0) {
          s = parseInt(time);
        }
      } else {
        return `${"00"}:${"00"}:${"00"}`;
      }

      return `${addZero(h)}:${addZero(m)}:${addZero(s)}`;

      function addZero(n) {
        return n < 10 ? `0${n}` : n;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";

.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  .title {
    margin-top: 0;
    font-size: 18px;
    text-align: left;
  }
  .menu {
    display: flex;
    align-items: center;
    .el-button {
      margin-left: 5px;
    }
  }
}
.el-card.test-card {
  color: $--color-blue-60;
  border-radius: 30px;

  &.success {
    color: #57d3b8;
    border-color: #57d3b8;
    background-color: #eefcf9;
  }

  &.lock-casual {
    background-color: #ececec;

    .center {
      h3 {
        color: #6f6f6f !important;
      }
    }
  }
  .body {
    .el-image {
      height: 200px;
      width: 100%;
    }

    .content {
      padding: 20px;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        img {
          height: 40px;
        }
        .new-box {
          background-color: #ff768d;
          color: #fff;
          padding: 3px 15px;
          border-radius: 15px;
        }

        .icon-box {
          width: 25px;
          height: 25px;

          border-radius: 50%;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          &.success {
            background-color: #57d3b8;
          }
          &.lock {
            background-color: #8e8e8e;
          }
        }
      }

      .center {
        h3 {
          color: #000;
        }
      }
    }
  }
}
</style>

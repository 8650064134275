<template>
  <DashboardTemplatePeriod
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :title="$t('message.titleHome')"
  >
    <div class="title-box">
      <div>
        <span class="title text-white"
          >{{ $t("home.prefixTotal") }} {{ tests.length }}
          {{ $t("home.suffixTotal") }}</span
        >
        <div class="menu">
          <span v-if="timeType === 1" class="text-white">{{
            `${$t("home.remainingTime")}: ${time}`
          }}</span>
          <span v-if="timeType === 2" class="text-white">{{
            `${$t("home.timeSpent")}: ${time}`
          }}</span>
        </div>
      </div>
      <div class="stat-box">
        <div class="stat-title-box">
          <p class="title">{{ $t("message.tileState") }}</p>
          <div class="stat-score">
            <span class="icon-box">
              <i class="el-icon-check"></i>
            </span>
            <div class="score-now">
              <span class="now">{{ state.done }}</span>
              <span class="total"> / {{ state.total }}</span>
            </div>
          </div>
        </div>
        <div
          class="pie-wrapper pie-wrapper--solid"
          :class="`progress-${getProgress(state.done, state.total)}`"
        >
          <span class="label"
            >{{ getProgress(state.done, state.total) }} %</span
          >
        </div>
      </div>
    </div>
    <div class="road-map">
      <div class="road-item">
        <div class="main">
          <RoadMapSVG fill="#708FFA" />
        </div>
        <div class="sub" :style="`width:${getWidthSub(state.done)}px`">
          <RoadMapSVG fill="#fff" />
        </div>
      </div>

      <div class="test-box">
        <div
          v-for="(item, index) in tests"
          :key="item.id"
          class="test-item"
          :class="getClassTest(item)"
          :id="item.code"
        >
          <div class="name-box">
            <img
              v-if="index % 2 === 0"
              :src="require(`../../assets/image/lineNameTest.png`)"
            />
            <h1>0{{ index + 1 }}</h1>
            <p>{{ item.name }}</p>
            <img
              v-if="index % 2 === 1"
              :src="require(`../../assets/image/lineNameTest.png`)"
            />
          </div>
          <img :src="require(`../../assets/image/${item.code}.png`)" />
          <span class="icon-box">
            <i
              v-if="getClassTest(item) === 'success'"
              class="el-icon-check"
            ></i>
            <i
              v-else-if="getClassTest(item) === 'fail'"
              class="el-icon-close"
            ></i>
            <i
              v-else-if="getClassTest(item) === 'lock'"
              class="el-icon-lock"
            ></i>
          </span>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <el-button @click="goToTestLevel(test._id)" class="btn-view-test play">
        <span>{{ $t("home.viewTest") }}</span>
        <i class="el-icon-arrow-right"></i
      ></el-button>
    </div>
  </DashboardTemplatePeriod>
</template>

<script>
import DashboardTemplatePeriod from "@/template/DashboardTemplatePeriod";
import { HTTP, HTTP_WEB_MAIN } from "@/service/axios";
import RoadMapSVG from "@/components/game/RoadMapSVG";

export default {
  components: {
    DashboardTemplatePeriod,
    RoadMapSVG,
  },
  computed: {
    surveyType() {
      return this.$store.state.surveyType;
    },
    steps() {
      return this.$store.state.steps;
    },
  },

  data() {
    return {
      tests: [],
      loading: true,
      langs: ["th", "en"],
      startTime: null,
      time: null,
      timeType: null,
      stateNumber: null,
      sessionId: null,
      allowViewScore: false,
      state: {
        done: 0,
        total: 0,
      },
      test: null,
    };
  },
  created() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  mounted() {
    const slider = document.querySelector(".road-map");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });

    this.fetchTestPeriod();
    this.fetchTests();
    this.fetchScoreOfWebMain();
  },
  methods: {
    async fetchScoreOfWebMain() {
      try {
        let res = await HTTP_WEB_MAIN.get(
          `/cognitive/log/test/${this.user.id}`
        );
        console.log("fetchScoreOfWebMain", res);
        if (res.status === 200) {
          this.allowViewScore = res.data.length > 0;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    goToViewScore() {
      window.open(`https://mindanalytica.com/surveys/${this.sessionId}/result`);
    },
    goToTestLevel(id) {
      this.$router.push(`/test/${id}`);
    },
    async fetchTests() {
      try {
        let path = `/tests?orderByField=number&orderBy=asc`;
        if (this.surveyType == "period") {
          path += "&periodAllow=yes";
        }
        let res = await HTTP.get(path);

        if (res.data.success) {
          let array = [];
          let done = 0;
          let total = 0;

          res.data.result.rows.map((item, index) => {
            let obj = item;
            obj.numberTest = index + 1;

            let success = this.successTest(item);
            obj.success = success;

            if (success) {
              done++;
            }
            total++;
            array.push(obj);
          });

          this.state = {
            done: done,
            total: total,
          };

          const test = array.length > 0 ? array[done] : null;

          if (test) {
            this.test = test;

            setTimeout(() => {
              const slider = document.querySelector(".road-map");
              const element = document.getElementById(test.code);

              slider.classList.add("scroll-smooth");
              slider.scrollLeft = element.offsetLeft;
              setTimeout(() => {
                slider.classList.remove("scroll-smooth");
              }, 300);
            }, 300);
          }

          this.tests = array;
        } else {
          this.tests = [];
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
        this.tests = [];
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    async fetchTestPeriod() {
      try {
        let res = await HTTP.get(`/log/test/periods/by/${this.surveyType}`);

        if (res.data.success) {
          if (res.data.result) {
            this.startTime = res.data.result.startTime;

            this.stateNumber = res.data.result.stateNumber;

            this.sessionId = res.data.result.sessionId;

            this.setTime();
            this.$store.commit("SET_STEPS", res.data.result.steps);
            if (typeof res.data.result.disconnect !== "undefined") {
              this.$store.commit("SET_DISCONNECT", res.data.result.disconnect);
            }
          } else {
            this.$socket.emit("endGame", {
              userId: this.user.id,
            });
          }
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    lockTest(item) {
      if (this.surveyType == "casual") {
        return false;
      } else if (this.surveyType == "period") {
        if (this.stateNumber === item.numberTest) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    setTime() {
      var myInterval = setInterval(() => {
        let date = null;
        let type = null;

        if (Date.now() > new Date(this.startTime).getTime() + 9000000) {
          date = new Date(this.startTime).getTime() + 10800000;
          type = 1;
        } else {
          date = new Date(this.startTime).getTime();
          type = 2;
        }

        this.timeType = type;
        this.time = this.getTime(date, type);

        if (Date.now() > new Date(this.startTime).getTime() + 10800000) {
          clearInterval(myInterval);
        }
      }, 1000);
    },
    getTime(date, type) {
      let now = Date.now();
      let time = 0;

      if (type === 1) {
        time = (date - now) / 1000;
      } else {
        time = (now - date) / 1000;
      }

      let h = 0;
      let m = 0;
      let s = 0;

      h = parseInt(time / 3600);

      time = time - h * 3600;

      if (time > 0) {
        m = parseInt(time / 60);
        time = time - m * 60;

        if (time > 0) {
          s = parseInt(time);
        }
      } else {
        return `${"00"}:${"00"}:${"00"}`;
      }

      return `${addZero(h)}:${addZero(m)}:${addZero(s)}`;

      function addZero(n) {
        return n < 10 ? `0${n}` : n;
      }
    },
    successTest(item) {
      let steps = this.steps.filter((f) => f.testId === item._id);

      if (steps.length == 1) {
        if (steps[0].level) {
          return steps[0].level;
        } else {
          return "fail";
        }
      } else if (steps.length == 2) {
        if (steps[0].level) {
          return steps[0].level;
        } else if (steps[1].level) {
          return steps[1].level;
        } else {
          return "fail";
        }
      } else {
        return null;
      }
    },
    getProgress(num, total) {
      let sum = num > 0 ? (num / total) * 100 : 0;
      return sum.toFixed(0);
    },
    getClassTest(data) {
      if (data.success && data.success !== "fail") {
        return "success";
      }
      if (data.success && data.success === "fail") {
        return "fail";
      } else if (this.lockTest(data) && !data.success) {
        return "lock";
      } else {
        return "now";
      }
    },
    getWidthSub(done) {
      let width = 0;
      switch (done) {
        case 0:
          width = 0;
          break;
        case 1:
          width = 320;
          break;
        case 2:
          width = 530;
          break;
        case 3:
          width = 740;
          break;
        case 4:
          width = 950;
          break;
        case 5:
          width = 1160;
          break;
        case 6:
          width = 1370;
          break;
        case 7:
          width = 1580;
          break;
        case 8:
          width = 1790;
          break;
        case 9:
          width = 2000;
          break;
        case 10:
          width = 2210;
          break;

        default:
          break;
      }

      return width;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";

.el-container {
  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10vw 0 10vw;
    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
    .title {
      margin-top: 0;
      font-size: 18px;
      text-align: left;
    }

    .menu {
      display: flex;
      align-items: center;
      .el-button {
        margin-left: 5px;
      }
    }

    .stat-box {
      background-image: url("../../assets/image/bg-score.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 364px;
      height: 64px;
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 500px) {
        margin-top: 10px;
      }

      .stat-title-box {
        .title {
          color: #010101;
          opacity: 0.5;
          font-size: 16px;
          margin: 0;
          padding-bottom: 5px;
        }

        .stat-score {
          display: flex;
          align-items: center;

          .icon-box {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: #57d3b8;
            i {
              font-size: 14px;
            }
          }
          .score-now {
            padding-left: 10px;
            .now {
              font-size: 32px;
              font-weight: 700;
              color: #0c092a;
            }

            .total {
              font-size: 24px;
              font-weight: 400;
              color: #737282;
            }
          }
        }
      }
    }
  }

  .road-map {
    position: relative;
    display: flex;
    overflow-x: auto;
    cursor: grab;
    align-items: center;
    padding-left: 10vw;
    padding-top: 90px;
    padding-bottom: 90px;

    @media screen and (max-width: 500px) {
      rotate: 90deg;
      overflow-x: unset;
      padding-top: 0;
      padding-bottom: 0;
    }
    .road-item {
      position: relative;
      .main {
        position: relative;
        z-index: 1;
      }
      .sub {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;

        overflow: hidden;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
    .test-box {
      padding-left: 10vw;
      position: absolute;
      top: 105px;

      @media screen and (max-width: 500px) {
        top: 15px;
      }
      .test-item {
        width: 165px;
        height: 165px;
        border-radius: 50%;
        position: absolute;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;

        &.success {
          border: 6px solid #57d3b8;
          background-color: #fff;

          .icon-box {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #57d3b8;
          }
        }

        &.fail {
          border: 6px solid #ff768d;
          background-color: #fff;

          .icon-box {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #ff768d;
          }
        }

        &.now {
          border: 6px solid #ff9b57;
          background-color: #fff;
        }

        &.lock {
          border: 0;
          background-color: #708ffa;

          .icon-box {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background-color: #708ffa;
          }
        }
        img {
          width: 90px;
        }

        .name-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          position: absolute;
          color: #fff;
          top: -150px;
          img {
            height: 40px;
            width: auto;
            margin: 10px 0;
          }
          h1 {
            margin: 0;
            color: #6a8afb;
            font-size: 32px;
          }
          p {
            margin: 0;
            font-size: 20px;
            text-align: center;
          }

          @media screen and (max-width: 500px) {
            width: 110px;
            img {
              display: none;
            }
            h1 {
              font-size: 26px;
            }
            p {
              font-size: 16px;
            }
          }
        }
        &:nth-child(1) {
          top: 0;
          left: 21px;
          .name-box {
            top: 190px;
            @media screen and (max-width: 500px) {
              align-items: flex-end;
              top: 220px;
              rotate: 270deg;
              h1,
              p {
                text-align: right;
              }
            }
          }
        }
        &:nth-child(2) {
          top: 94px;
          left: 232px;
          .name-box {
            top: -150px;
            @media screen and (max-width: 500px) {
              align-items: flex-start;
              top: -100px;
              rotate: 270deg;
              h1,
              p {
                text-align: left;
              }
            }
          }
        }
        &:nth-child(3) {
          top: 0;
          left: 443px;
          .name-box {
            top: 190px;
            @media screen and (max-width: 500px) {
              align-items: flex-end;
              top: 220px;
              rotate: 270deg;
              h1,
              p {
                text-align: right;
              }
            }
          }
        }
        &:nth-child(4) {
          top: 94px;
          left: 657px;
          .name-box {
            top: -165px;
            @media screen and (max-width: 500px) {
              align-items: flex-start;
              top: -110px;
              rotate: 270deg;
              h1,
              p {
                text-align: left;
              }
            }
          }
        }
        &:nth-child(5) {
          top: 0;
          left: 866px;
          .name-box {
            top: 190px;
            @media screen and (max-width: 500px) {
              align-items: flex-end;
              top: 220px;
              rotate: 270deg;
              h1,
              p {
                text-align: right;
              }
            }
          }
        }
        &:nth-child(6) {
          top: 94px;
          left: 1075px;
          .name-box {
            top: -165px;
            @media screen and (max-width: 500px) {
              align-items: flex-start;
              top: -110px;
              rotate: 270deg;
              h1,
              p {
                text-align: left;
              }
            }
          }
        }
        &:nth-child(7) {
          top: 0;
          left: 1285px;
          .name-box {
            top: 190px;
            @media screen and (max-width: 500px) {
              align-items: flex-end;
              top: 220px;
              rotate: 270deg;
              h1,
              p {
                text-align: right;
              }
            }
          }
        }
        &:nth-child(8) {
          top: 94px;
          left: 1496px;
          .name-box {
            top: -165px;
            @media screen and (max-width: 500px) {
              align-items: flex-start;
              top: -120px;
              rotate: 270deg;
              h1,
              p {
                text-align: left;
              }
            }
          }
        }
        &:nth-child(9) {
          top: 0;
          left: 1706px;
          .name-box {
            top: 190px;
            @media screen and (max-width: 500px) {
              align-items: flex-end;
              top: 220px;
              rotate: 270deg;
              h1,
              p {
                text-align: right;
              }
            }
          }
        }
        &:nth-child(10) {
          top: 94px;
          left: 1919px;
          .name-box {
            top: -165px;
            @media screen and (max-width: 500px) {
              align-items: flex-start;
              top: -110px;
              rotate: 270deg;
              h1,
              p {
                text-align: left;
              }
            }
          }
        }
        &:nth-child(11) {
          top: 0;
          left: 2125px;
          .name-box {
            top: 190px;
            @media screen and (max-width: 500px) {
              align-items: flex-end;
              top: 220px;
              rotate: 270deg;
              h1,
              p {
                text-align: right;
              }
            }
          }
        }

        &.success,
        &.fail,
        &.now {
          &:nth-child(1) {
            top: 0;
            left: 21px;
          }
          &:nth-child(2) {
            top: 94px;
            left: 232px;
          }
          &:nth-child(3) {
            top: 0;
            left: 442px;
          }
          &:nth-child(4) {
            top: 94px;
            left: 652px;
          }
          &:nth-child(5) {
            top: 0;
            left: 861px;
          }
          &:nth-child(6) {
            top: 94px;
            left: 1072px;
          }
          &:nth-child(7) {
            top: 0;
            left: 1281px;
          }
          &:nth-child(8) {
            top: 94px;
            left: 1491px;
          }
          &:nth-child(9) {
            top: 0;
            left: 1701px;
          }
          &:nth-child(10) {
            top: 94px;
            left: 1911px;
          }
          &:nth-child(11) {
            top: 0;
            left: 2121px;
          }
        }
      }
    }
  }
  .footer-box {
    text-align: center;
    @media screen and (max-width: 500px) {
      position: absolute;
      top: 70%;
      left: 20px;
    }
  }
}
</style>
